import axios, { AxiosResponse } from 'axios';
import { authorizeRequest, removeDupsFromArray } from './util.service';
import {
    IUserCollection, IUserCollectionResponse, IReleaseDataResponse, IDetailedReleaseInformation,
    IAddToCollectionResponse, IOAuthIdentityResponse, ICollectionValueResponse, IUserWantResponse
} from './types';
import Cookies from 'js-cookie';
import rateLimit from 'axios-rate-limit';

const personalApi = axios.create({ baseURL: 'https://api.discogs.com' });

// TODO: request error handling
personalApi.interceptors.request.use(authorizeRequest);

personalApi.interceptors.response.use(response => response, async (error) => {
    switch (error.response.status) {
        case 401:
            Cookies.remove(`${process.env.REACT_APP_LOGIN_COOKIE}`);
            break;
        default:
            break;
    }
    return Promise.reject(error);
});

const rateLimitPersonalApi = rateLimit(personalApi, { maxRPS: 1 });

export const getCollectionValue = async (username: string): Promise<ICollectionValueResponse> =>
    await rateLimitPersonalApi.get(`/users/${username}/collection/value`).then(d => d.data);

export const getUserIdentity = async (): Promise<IOAuthIdentityResponse> =>
    await rateLimitPersonalApi.get(`/oauth/identity`).then(d => d.data);

export const removeFromUserCollection = async (username: string, releaseId: number, instanceId: number): Promise<void> =>
    await rateLimitPersonalApi.delete(`/users/${username}/collection/folders/0/releases/${releaseId}/instances/${instanceId}`);

export const addToUserCollection = async (username: string, releaseId: number): Promise<IAddToCollectionResponse> =>
    await rateLimitPersonalApi.post(`/users/${username}/collection/folders/1/releases/${releaseId}`).then(d => d.data);

export const getUserCollection = async (username: string, page: number): Promise<IUserCollection> =>
    await rateLimitPersonalApi.get(`/users/${username}/collection/folders/0/releases?page=${page}&per_page=500&sort=artist&sort_order=asc`).then((d: AxiosResponse<IUserCollectionResponse>) => {
        return d.data.releases.reduce((acc, release) => {
            const { basic_information } = release;
            const artist = basic_information.artists.map(artist => artist.name);
            const labels = basic_information.labels.map(label => label.name);
            const catno = basic_information.labels.map(label => label.catno);

            return {
                ...acc,
                items: [...acc.items, {
                    title: basic_information.title,
                    artist: removeDupsFromArray(artist).join(', '),
                    year: basic_information.year,
                    labels: removeDupsFromArray(labels).join(', '),
                    genres: basic_information.genres.join(', '),
                    catno: removeDupsFromArray(catno).join(', '),
                    releaseId: basic_information.id,
                    image: basic_information.cover_image,
                    instanceId: release.instance_id
                }]
            }
        }, {
            pages: d.data.pagination.pages,
            numberOfItems: d.data.pagination.items,
            items: [],
            value: ''
        } as IUserCollection);
    });

export const getUserWantList = async (username: string, page: number): Promise<any> =>
    await rateLimitPersonalApi.get(`/users/${username}/wants?page=${page}&per_page=100`).then((d: AxiosResponse<IUserWantResponse>) => {
        return d.data.wants.reduce((acc, want) => {
            const { basic_information } = want;
            const artist = basic_information.artists.map(artist => artist.name);
            const labels = basic_information.labels.map(label => label.name);
            const catno = basic_information.labels.map(label => label.catno);

            return {
                ...acc,
                items: [...acc.items, {
                    title: basic_information.title,
                    artist: removeDupsFromArray(artist).join(', '),
                    year: basic_information.year,
                    labels: removeDupsFromArray(labels).join(', '),
                    genres: basic_information.genres.join(', '),
                    catno: removeDupsFromArray(catno).join(', '),
                    releaseId: basic_information.id,
                    image: basic_information.cover_image,
                    instanceId: want.instance_id
                }]
            }
        }, {
            pages: d.data.pagination.pages,
            numberOfItems: d.data.pagination.items,
            items: [],
            value: ''
        } as IUserCollection);

    });

export const getReleaseInformation = async (releaseId: number): Promise<IDetailedReleaseInformation> =>
    await rateLimitPersonalApi.get(`/releases/${releaseId}`).then((d: AxiosResponse<IReleaseDataResponse>) => {
        const { data } = d;

        return {
            releaseId: data.id,
            title: data.title,
            artist: data.artists_sort,
            artists: data.artists.map(artist => artist.name).join(', '),
            genres: data.genres ? data.genres.join(', ') : undefined,
            styles: data.styles ? data.styles.join(', ') : undefined,
            year: data.year,
            url: data.uri,
            country: data.country,
            barcodes: data.identifiers ? data.identifiers.filter(identifier => identifier.type === 'Barcode').map(identifier => identifier.value).join(', ') : undefined,
            videos: data.videos || [],
            releaseDate: data.released_formatted,
            trackList: data.tracklist ? data.tracklist.filter(track => track.type_ === 'track') : [],
            formats: data.formats.map(format => format.name).join(', '),
            labels: data.labels.map(label => label.name).join(', '),
            catnos: data.labels.map(label => label.catno).join(', '),
            image: data.images ? data.images[0].resource_url : ''
        };
    });
