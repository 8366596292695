import * as React from 'react';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { Cancel, Search } from '@material-ui/icons';
import { isMobile } from "react-device-detect";

const useStyles = makeStyles(theme => ({
    searchBoxRoot: {
        width: isMobile ? '70%' : '30%'
    }
}));

export default (props: IProps) => {
    const { placeholder, onChange, onClear } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState('');

    return (
        <TextField
            className={!props.className ? classes.searchBoxRoot : props.className}
            variant={'outlined'}
            color="secondary"
            placeholder={placeholder}
            onChange={(e) => {
                onChange(e);
                setValue(e.target.value);
            }}
            value={value}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search color="secondary"/>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {value.length > 0 && <Cancel color="secondary" style={{ cursor: 'pointer' }} onClick={() => {
                            onClear();
                            setValue('');
                        }} />}
                    </InputAdornment>
                )
            }}
        />
    )
}

interface IProps {
    className?: string;
    placeholder: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClear: () => void;
}