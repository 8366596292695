import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    popover: {
        height: 400
    },
    content: {
        padding: theme.spacing()
    },
    icon: { cursor: 'pointer' }
}));