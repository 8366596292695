import * as React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { AppRouter } from './component';
import { SnackbarProvider } from 'notistack';
import { isMobile } from "react-device-detect";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export default () => (
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'center', vertical: isMobile ? 'bottom' : 'top' }}>
      <CssBaseline />
      <AppRouter history={history} />
    </SnackbarProvider>
  </ThemeProvider>
);