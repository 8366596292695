import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography, Button } from '@material-ui/core';
import { Divider } from '@jayjonesdev/react-material-ui-library';
import useStyles from './style';
import ImageTile from '../imageTile/imageTile';
import { IDetailedReleaseInformation } from '../../service/types';

export default (props: IProps & DialogProps) => {
    const classes = useStyles();
    const { item, isNew, exists, addItem, close, removeItem } = props;

    const dialogProps = (): DialogProps => {
        let { item, isNew, addItem, removeItem, close, exists, ...rest } = props;
        return rest;
    }

    return (
        <Dialog {...dialogProps()} fullWidth>
            <DialogTitle className={classes.title}>{item?.artist} - {item?.title}</DialogTitle>
            <DialogContent className={classes.content}>
                <div className={classes.imageContainer}>
                    <ImageTile height={500} width={500} imageKey='image' item={item} />
                </div>
                <div className={classes.information}>
                    <Typography variant="h6"><b>Album Information</b></Typography>
                    <Divider color='primary' size={2} />
                    {item?.artists && <Typography variant="body1"><b>Artist(s):</b> {item.artists}</Typography>}
                    {item?.title && <Typography variant="body1"><b>Title:</b> {item.title}</Typography>}
                    {(item?.year !== undefined && item?.year !== 0) && <Typography variant="body1"><b>Year:</b> {item.year}</Typography>}
                    {item?.labels && <Typography variant="body1"><b>Label(s):</b> {item.labels}</Typography>}
                    {item?.genres && <Typography variant="body1"><b>Genres:</b> {item.genres}</Typography>}
                    {item?.barcodes && <Typography variant="body1"><b>Barcode(s):</b> {item.barcodes}</Typography>}
                    {item?.catnos && <Typography variant="body1"><b>Catalog #:</b> {item.catnos}</Typography>}
                    {item?.trackList && <>
                        {item.trackList.length > 0 && <div>
                            <br />
                            <Typography variant="h6"><b>Track List</b></Typography>
                            <Divider color='primary' size={2} />
                        </div>}
                        {item.trackList.map((track, index) => {
                            const duration = track.duration.length > 0 ? ` - ${track.duration}` : '';
                            const featuringArtists = track.extraartists ? track.extraartists.filter(artist => artist.role === 'Featuring').map(artist => artist.name) : [];
                            const featuring = track.extraartists && featuringArtists.length > 0 ? ` - ft. ${featuringArtists.join(', ')}` : '';
                            return (<Typography key={index} variant="body1"><b>{track.position}.</b> {track.title}{duration}{featuring}</Typography>);
                        })}
                    </>}
                    <br />
                    {item?.releaseId && <Typography variant="subtitle2"><a target="_blank" rel="noopener noreferrer" href={`https://www.discogs.com/release/${item.releaseId}`}>Data provided by Discogs.</a></Typography>}
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                {isNew && <Button variant='outlined' color='secondary' onClick={addItem}>Add</Button>}
                {exists && <Button variant='outlined' color='secondary' onClick={removeItem}>Remove</Button>}
                <Button color="primary" variant='contained' onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface IProps {
    item: IDetailedReleaseInformation | undefined;
    isNew?: boolean;
    exists?: boolean;
    addItem: () => void;
    removeItem: () => void;
    close: () => void;
}