import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        ...theme.mixins.toolbar,
    },
    logo: {
        height: 52,
        boxSizing: 'border-box',
        cursor: 'pointer'
    },
    appbar: { 
        zIndex: 101,
        background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`
    }
}));