import React from 'react';
import { Card, CardActionArea, CardMedia } from '@material-ui/core';
import useStyles from './imageTile.style';

const ImageTile = (props: {
    onClick?: any;
    height?: number;
    width?: number;
    item: any;
    imageKey: string;
}) => {
    const { height = 300, width = 300, item, onClick, imageKey } = props;
    const classes = useStyles({ width });
    return (
        <Card className={classes.root}>
            <CardActionArea disabled={onClick === undefined}>
                <CardMedia
                    component="img"
                    alt={item.title}
                    height={height}
                    image={item[imageKey]}
                    title={`${item.artist} - ${item.title}`}
                    onClick={() => onClick && onClick(item)}
                />
            </CardActionArea>
        </Card>
    )
}

export default ImageTile;