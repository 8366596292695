import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        height: '100vh',
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1
    },
    buttonBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing()
    },
    content: { 
        padding: theme.spacing(4, 4),
        height: '100vh',
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1
     },
    searchBox: { width: '30%' },
    outlinedButton: {
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        backgroundColor: '#fff'
    },
    tableContainer: {
        height: '90vh',
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1,
        paddingBottom: theme.spacing() * 6
    }
}));
