import { makeStyles } from '@material-ui/core';

export default makeStyles({
    root: {
        display: 'flex',
        alignItems: 'stretch',
        height: '100vh',
    },
    login: {
        height: '100vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#D1E3DD',
        alignItems: 'center',
    },
    logo: {
        height: '38%',
    },
    terms: {
        display: 'flex',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',

    },
    discogLogin: {
        marginBottom: 10,
        backgroundColor: 'black',
        width: '20%',
        textAlign: 'center',
        color: 'white',
        '&:hover': {
            backgroundColor: 'grey'
        }
    },
    link: { textDecoration: 'none' }
});