import { VariableSizeGrid as Grid } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import memoize from "memoize-one";
import ImageTile from "../imageTile/imageTile";
import useStyles from './style';
import { ICollectionItem } from "../../service/types";

export default (props: IProps) => {
    const { data, hasNextPage, imageKey, loadNextPage, onClick } = props;
    const ROW_SIZE: number = 300;
    const classes = useStyles();
    const createItemData = memoize((classes, data, onClick, loadNextPage) => ({
        classes,
        items: data,
        onClick,
        loadNextPage
    }));
    const key = imageKey;
    const itemData = createItemData(classes, data, onClick, loadNextPage);

    return (
        <div className={classes.root}>
            <AutoSizer>
                {({ height, width }) => {
                    const columnCount = data.length <= Math.floor(width / ROW_SIZE) ? data.length : Math.floor(width / ROW_SIZE);
                    const columnWidth = Math.floor((width - ROW_SIZE) / (columnCount - 1));
                    const itemKey = (params: {
                        columnIndex: number;
                        rowIndex: number;
                        data: IData;
                    }) => {
                        const { rowIndex, columnIndex, data } = params;
                        let index: number;
                        if (data.items.length <= columnCount - 1) index = columnIndex;
                        else index = (rowIndex * columnCount) + (columnIndex);
                        return data.items[index]?.releaseId || -1;
                    };

                    return (
                        <Grid
                            height={height}
                            width={width}
                            columnCount={columnCount}
                            rowCount={Math.ceil(itemData.items.length / columnCount)}
                            columnWidth={(index: number) => (index < (columnCount - 1)) ? columnWidth : ROW_SIZE}
                            rowHeight={(index: number) => columnWidth}
                            estimatedRowHeight={columnWidth}
                            itemData={itemData}
                            itemKey={itemKey}
                        >
                            {(props: {
                                data: any;
                                style: any;
                                columnIndex: number;
                                rowIndex: number;
                            }) => {
                                let index: number;
                                const { data, rowIndex, columnIndex, style } = props;

                                if (data.items.length <= columnCount) index = columnIndex;
                                else index = (rowIndex * columnCount) + (columnIndex);

                                const item = data.items[index];
                                if (hasNextPage && index === data.items.length - 1) data.loadNextPage();

                                if (item === undefined) return (null);
                                return (<div style={style}>
                                    <ImageTile {...props} item={item} imageKey={key} onClick={data.onClick} />
                                </div>)
                            }}
                        </Grid>
                    )
                }}
            </AutoSizer>
        </div>
    )
}

interface IProps {
    data: ICollectionItem[];
    imageKey: string;
    hasNextPage?: boolean;
    loadNextPage?: () => void;
    onClick: (item: ICollectionItem) => void;
}

interface IData {
    classes: any;
    items: ICollectionItem[];
    onClick: (item: ICollectionItem) => void;
}