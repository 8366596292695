import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, DialogProps, DialogTitle, IconButton, Button } from '@material-ui/core';
import useStyles from './style';
import { Divider } from '@jayjonesdev/react-material-ui-library';
import { Instagram } from '@material-ui/icons';

export default (props: IProps & DialogProps) => {
    const { itemCount, collectionValue } = props;
    const classes = useStyles();

    const dialogProps = (): DialogProps => {
        let { itemCount, collectionValue, ...rest } = props;
        return rest;
    }

    return (
        <Dialog {...dialogProps()} fullWidth>
            <DialogTitle className={classes.title}>Information</DialogTitle>
            <DialogContent className={classes.content}>
                <div>
                    <Typography variant={'body1'}><b># of items in collection: </b>{itemCount}</Typography>
                    <Typography variant={'body1'}><b>Collection value (USD): </b>{collectionValue}</Typography>
                    <br />
                    <Divider color='primary' size={2} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color={'secondary'} aria-label='Menu' onClick={() => window.open(`https://www.instagram.com/provinyl_io/`, '_blank')}>
                            <Instagram />
                        </IconButton>
                        <Typography variant={'body2'} onClick={() => window.open(`https://www.instagram.com/provinyl_io/`, '_blank')}>Visit us on Instagram</Typography>
                    </div>
                    <Typography variant={'subtitle2'}><i>This application uses Discogs’ API but is not affiliated with, sponsored or endorsed by Discogs. ‘Discogs’ is a trademark of Zink Media, LLC.</i></Typography>
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button color="primary" variant='contained' onClick={props.onBackdropClick}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface IProps {
    itemCount: number;
    collectionValue: string;
}