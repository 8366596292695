import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
        ...theme.mixins.toolbar,
    },
    logo: {
        height: 42,
        boxSizing: 'border-box'
    },
    appbar: { zIndex: 101 },
    data: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: theme.spacing() * 3,
        paddingRight: theme.spacing() * 3,
        paddingBottom: theme.spacing(),
        paddingTop: theme.spacing() * 3,
        backgroundColor: '#D1E3DD'
    },
    collectionButtonBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing() * 2
    },
    root: {
        height: window.innerHeight,
        backgroundColor: '#D1E3DD',
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        flex: 1
    },
    collectionText: {
        marginTop: theme.spacing()
    },
    icons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    content: {
        color: 'white'
    },
    subscriptions: {
        padding: theme.spacing(),
        overflowY: 'auto'
    },
    dropIn: {
        padding: theme.spacing()
    },
    buttonBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing()
    },
    notice: {
        padding: theme.spacing() * .5
    },
    searchBox: { width: '70%' },
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing()
    },
}));