import * as React from 'react';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import { isMobile } from "react-device-detect";

const useStyles = makeStyles(theme => ({
    iconRoot: {
        marginLeft: isMobile ? 0 : theme.spacing(),
    }
}));

export default (props: IProps) => {
    const classes = useStyles();
    const { title, iconLabel, onClick, color } = props;

    return (
        <Tooltip disableFocusListener disableTouchListener title={title}>
            <IconButton className={classes.iconRoot} style={props.style} color={color ? color : 'primary'} aria-label='Menu' onClick={onClick}>
                {iconLabel}
            </IconButton>
        </Tooltip>
    )
}

interface IProps {
    title: string;
    iconLabel: JSX.Element;
    color?: 'primary' | 'secondary';
    onClick: (event: any) => void;
    style?: any;
}