import * as React from 'react';
import { History } from 'history';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { LoginPage, CollectionPage, RedirectPage, PublicCollectionPage } from '../page';
import { isMobile } from "react-device-detect";
import { CollectionMobile, LoginMobile, PublicCollectionMobile } from '../page/mobile';
import Cookies from 'js-cookie';

const AppRouter: React.FC<IProps> = (props) => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={'/'} component={isMobile ? LoginMobile : LoginPage} exact={true} />
                <Route path={'/Login'} component={isMobile ? LoginMobile : LoginPage} />
                <Route path={'/View/:username'} component={isMobile ? PublicCollectionMobile : PublicCollectionPage} />
                <RequireAuth {...props} path={'/Collection'} component={isMobile ? CollectionMobile : CollectionPage} />
                <Route path={'/Unauthorized'} component={(props: any) => <RedirectPage {...props} title={'Unauthorized'} message={'You are not authorized to view this page.'} />} exact={true} />
                <Route path={'*'} render={(props: any) => <RedirectPage {...props} title={'Error'} message={'Page not found.'} />} />
            </Switch>
        </BrowserRouter>
    )
}

export default AppRouter;

const RequireAuth = (props: any) => {
    const cookie = Cookies.get(`${process.env.REACT_APP_LOGIN_COOKIE}`) || '';

    if (cookie.length === 0)
        return <Route component={(props: any) => <RedirectPage {...props} title={'Unauthorized'} message={'You are not authorized to view this page.'} />} exact={true} />;
    return <Route {...props} />;
};

interface IProps {
    history: History<any>;
}