import * as React from 'react';
import { Typography, Button, IconButton } from '@material-ui/core';
import { MobileList } from '../../../component/complex';
import icon from '../../../assets/provinyl_logomark_6E7DAB_icon.png';
import { History } from 'history';
import { Add, Link, SortByAlpha, ViewDay, ViewList, ExitToApp as Logout, Close, ArrowForward } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import AutoSizer from "react-virtualized-auto-sizer";
import findIndex from 'lodash.findindex';
import { ICollectionItem, IUserCollection } from '../../../service/types';
import { searchForReleaseBy } from '../../../service/database.service';
import { addToUserCollection, getUserCollection, getUserIdentity } from '../../../service/discog.service';
import { Appbar, ButtonBar, MenuButton, MenuButtonItem, Searchbox, Divider, LoadingOverlay } from '@jayjonesdev/react-material-ui-library';
import useStyles from './style';
import { uniqByWithComparator } from '../../../service/util.service';
import Cookies from 'js-cookie';
import { AuthService } from '../../../service';

const Collection: React.FC<IProps> = (props) => {
    const { history } = props;
    const [searchString, setSearchString] = React.useState('');
    const [searchByProps, setSearchByProps] = React.useState<{
        searchPlaceholder: string;
        searchBy: string;
    }>({
        searchPlaceholder: 'Search...',
        searchBy: ''
    });
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [items, setItems] = React.useState<ICollectionItem[]>([]);
    const [viewType, setViewType] = React.useState<'text' | 'image'>('image');
    const [sortDirection, setSortDirection] = React.useState<'ASC' | 'DESC'>('ASC');
    const [scan, setScan] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<ICollectionItem[]>([]);
    const [username, setUsername] = React.useState<string>('');
    const [collection, setCollection] = React.useState<IUserCollection>({
        pages: 0,
        numberOfItems: 0,
        items: [],
        value: ''
    });
    const [searching, setSearching] = React.useState<boolean>(false);
    const [timer, setTimer] = React.useState<NodeJS.Timeout>();
    const [searchDisabled, setSearchDisabled] = React.useState<boolean>(true);

    let menuButtonRef = React.createRef<any>();

    React.useEffect(() => {
        setLoading(true);
        try {
            getUserIdentity().then(identity => {
                setUsername(identity.username);
                fetchUserCollection(identity.username);
            }).catch(e => {
                setLoading(false);
                enqueueSnackbar('Authentication error, please login again.', { variant: 'error' });
                logout();
            });
        } catch (e) {
            setLoading(false);
            enqueueSnackbar('Unable to load collection.', { variant: 'error' });
        }
    }, []);

    React.useEffect(() => {
        search(searchString);
    }, [searchString, sortDirection]);

    const search = (searchString: string) => {
        let sortedData = searchString.length > 0 ? collection.items.filter(item => item.artist.toLowerCase().includes(searchString.toLowerCase())
            || item.title.toLowerCase().includes(searchString.toLowerCase())
            || item.labels.toLowerCase().includes(searchString.toLowerCase())
            || item.catno.toLowerCase().includes(searchString.toLowerCase())
            || item.genres.toLowerCase().includes(searchString.toLowerCase())) : collection.items;

        sortedData.sort((a, b) => {
            switch (sortDirection) {
                case 'ASC':
                    if (a.artist.toLowerCase() > b.artist.toLowerCase()) return 1;
                    if (b.artist.toLowerCase() > a.artist.toLowerCase()) return -1;
                    return 0;
                case 'DESC':
                    if (b.artist.toLowerCase() > a.artist.toLowerCase()) return 1;
                    if (a.artist.toLowerCase() > b.artist.toLowerCase()) return -1;
                    return 0;
                default:
                    if (a.artist.toLowerCase() > b.artist.toLowerCase()) return 1;
                    if (b.artist.toLowerCase() > a.artist.toLowerCase()) return -1;
                    return 0;
            }
        });
        setData(sortedData);
    };
    const exitSearch = () => {
        setSearchByProps({
            ...searchByProps,
            searchPlaceholder: 'Search...'
        });
        setItems([]);
        setSearching(false);
        setSearchString('');
    }
    const fetchUserCollection = (username: string) => {
        let userCollection: IUserCollection = {
            pages: 0,
            numberOfItems: 0,
            items: [],
            value: ''
        };
        getUserCollection(username, 1).then(async (d) => {
            userCollection = d;
            userCollection.items = await uniqByWithComparator(userCollection.items, 'releaseId', 0);
        }).finally(async () => {
            setCollection(userCollection);
            setData(userCollection.items);
            setLoading(false);
            if (1 < userCollection.pages) await fetchPage(username, 2, userCollection);
            else setSearchDisabled(false);
        });
    }

    const fetchPage = async (username: string, page: number, userCollection: IUserCollection) => {
        const { pages, items } = userCollection;
        let newCollection = userCollection;

        await getUserCollection(username, page).then(async (d) => {
            const updatedItems = await uniqByWithComparator([...items, ...d.items], 'releaseId', 0);
            newCollection = {
                ...userCollection,
                items: updatedItems
            }
            setCollection(newCollection);
            setData(updatedItems);
        }).finally(() => {
            if (page < pages) {
                const nextPage = page + 1;
                fetchPage(username, nextPage, newCollection);
            } else setSearchDisabled(false);
        });
    }

    const addItem = async (item: ICollectionItem) => {
        setLoading(true);
        try {
            await addToUserCollection(username, item!.releaseId).then(addedItem => {
                setCollection({
                    ...collection,
                    numberOfItems: collection.numberOfItems + 1,
                    items: findIndex(collection.items, { releaseId: item!.releaseId }) !== -1 ? collection.items : [...collection.items, {
                        title: item!.title,
                        artist: item!.artist,
                        year: item!.year,
                        labels: item!.labels,
                        genres: item?.genres || '',
                        catno: item!.catno,
                        releaseId: item!.releaseId,
                        image: item?.image || '',
                        instanceId: addedItem.instance_id
                    }]
                });
                exitSearch();
                setLoading(false);
                enqueueSnackbar(`${item!.title} - ${item!.artist} has successfully been added.`, { variant: 'success' });
            })
        } catch (e) {
            enqueueSnackbar(`Unable to add ${item!.title} - ${item!.artist}.`, { variant: 'error' });
            setLoading(false);
        }
    }

    const handleBarcodeReaderOnScan = async (barcode: string) => {
        if (barcode.length > 2) {
            setLoading(true);
            try {
                await searchForReleaseBy('barcode', barcode).then(items => {
                    setItems(items);
                    setSearching(true);
                }).finally(() => setLoading(false));
            } catch (e) {
                setLoading(false);
                enqueueSnackbar('Unable to find record.', { variant: 'error' });
            }
        }
    }

    const searchBy = async () => {
        let items = [];
        setLoading(true);

        try {
            items = await searchForReleaseBy(searchByProps.searchBy, searchString);
            setItems(items);
            if (items.length === 0) enqueueSnackbar(`No records found matching that criteria.`, { variant: 'warning' });
        } catch (e) { enqueueSnackbar(`Error searching for record.`, { variant: 'error' }); }
        setLoading(false);
    };

    const logout = async () => {
        await AuthService.logout();
        Cookies.remove(`${process.env.REACT_APP_LOGIN_COOKIE}`);
        history.push('/Login');
    }
    const copyShareableLink = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_URL}/View/${username}`);
        enqueueSnackbar('Shareable link copied.', { variant: 'success' });
    }
    const onSearchBoxClear = () => setSearchString('');
    const onSearchBoxChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchString(e.target.value);
    const closeMenuButton = () => menuButtonRef.current!.closeMenu();
    const addBy = (searchBy: string, placeholder: string) => {
        closeMenuButton();
        setSearchByProps({
            searchPlaceholder: `${placeholder}...`,
            searchBy
        });
        setSearching(true);
    }
    const sort = () => setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    const cancelSearch = () => {
        setItems([]);
        setSearchString('');
        setSearching(false);
        setSearchByProps({
            ...searchByProps,
            searchPlaceholder: 'Search...'
        })
    }
    const openerScanner = () => {
        let timer = setTimeout(() => {
            if (scan) {
                timer && clearTimeout(timer);
                enqueueSnackbar('Unable to read barcode.', { variant: 'error' });
                setScan(false);
            }
        }, 12000);
        setTimer(timer);
        setScan(true);
    }
    const navigateToLogin = () => history.push('/Login');

    return (
        <div className={classes.root}>
            <>
                <Appbar color='primary' className={classes.appbar}>
                    <img className={classes.logo} src={icon} alt="logo" onClick={navigateToLogin} />
                    <ButtonBar>
                        <IconButton
                            aria-label="donate action"
                            aria-haspopup="false"
                            onClick={() => window.open('http://paypal.me/jayjonesdev', '_blank')}
                            color="inherit"
                            title='Donate'
                        >
                            <Typography variant="body2">Donate</Typography>
                        </IconButton>
                        <IconButton
                            aria-label="share action"
                            aria-haspopup="false"
                            onClick={copyShareableLink}
                            color="inherit"
                            title='Shareable Link'
                        >
                            <Link />
                        </IconButton>
                        <IconButton
                            aria-label="logout action"
                            aria-haspopup="false"
                            onClick={logout}
                            color="inherit"
                            title='Logout'
                        >
                            <Logout />
                        </IconButton>
                    </ButtonBar>
                </Appbar>
                <div className={classes.toolbar} />
            </>
            {!scan && <div className={classes.data}>
                <div className={classes.searchBar}>
                    <Searchbox className={classes.searchBox} placeholder={searchDisabled ? 'Loading...' : searchByProps.searchPlaceholder} color='primary' value={searchString}
                        onChange={onSearchBoxChange} onClear={onSearchBoxClear} variant='outlined' disabled={searchDisabled} />
                    <div className={classes.icons}>
                        {!searching && <>
                            <MenuButton variant='text' icon={<Add />} ref={menuButtonRef}>
                                <MenuButtonItem color='secondary' onClick={() => addBy('catno', 'Catalog #')}>Add By Catalog #</MenuButtonItem>
                                <MenuButtonItem color='secondary' onClick={() => addBy('title', 'Title')}>Add By Album Title</MenuButtonItem>
                                <MenuButtonItem color='secondary' onClick={openerScanner}>Scan Barcode</MenuButtonItem>
                            </MenuButton>
                            <IconButton
                                aria-label="sort action"
                                aria-haspopup="false"
                                onClick={sort}
                                color="inherit"
                                title='Sort By Artist'
                            >
                                <SortByAlpha />
                            </IconButton>
                        </>}
                        {searching && <>
                            <IconButton
                                aria-label="search action"
                                aria-haspopup="false"
                                onClick={searchBy}
                                color="inherit"
                                title='Search'
                            >
                                <ArrowForward />
                            </IconButton>
                            <IconButton
                                aria-label="cancel search action"
                                aria-haspopup="false"
                                onClick={cancelSearch}
                                color="inherit"
                                title='Cancel Search'
                            >
                                <Close />
                            </IconButton>
                        </>}
                        {viewType === 'image' &&
                            <IconButton
                                aria-label="text view type switch action"
                                aria-haspopup="false"
                                onClick={() => setViewType('text')}
                                color="inherit"
                                title='view type to text'
                            >
                                <ViewList />
                            </IconButton>}
                        {viewType === 'text' && <IconButton
                            aria-label="image view type switch action"
                            aria-haspopup="false"
                            onClick={() => setViewType('image')}
                            color="inherit"
                            title='view type to image'
                        >
                            <ViewDay />
                        </IconButton>}
                    </div>
                </div>
                <Divider color='primary' size={2} />
                {!loading && data.length === 0 && searchString.length === 0 && <Typography variant={'subtitle1'}>Please add items to your collection.</Typography>}
                {!searching && <MobileList data={data} listType={viewType} onClick={() => { }} />}
                {searching && <MobileList data={items} listType={viewType} onClick={addItem} isNew={true} />}
            </div>}
            {scan && <div style={{ display: 'flex', alignContent: 'stretch', flexDirection: 'column', width: '100%', height: '100vh', backgroundColor: '#D1E3DD' }}>
                <Typography variant={'subtitle2'} className={classes.notice}><b><i>*Please use Safari if on iOS. Camera permissions must be enabled.*</i></b></Typography>
                <AutoSizer>
                    {({ height, width }) => (
                        <BarcodeScannerComponent
                            width={width}
                            height={height}
                            onUpdate={(err: any, result: any) => {
                                if (result) {
                                    handleBarcodeReaderOnScan(result.text.replace(/^0+/, ''));
                                    timer && clearTimeout(timer);
                                    setScan(false);
                                    setLoading(true);
                                }
                            }}
                        />
                    )}
                </AutoSizer>
                <Button fullWidth style={{ marginLeft: 0 }} color="secondary" variant="contained" onClick={() => setScan(false)}>
                    Close
                </Button>
            </div>}
            <LoadingOverlay open={loading} type='circular' color="primary" label='' />
        </div>
    )
}

export default Collection;

interface IProps {
    history: History<any>;
}