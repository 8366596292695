import axios, { AxiosResponse } from 'axios';
import { removeDupsFromArray, uniqByWithComparator } from './util.service';
import { ICollectionItem, ISearchByResponse, ISearchByResults, IUserCollection, IUserCollectionResponse } from './types';
import Cookies from 'js-cookie';
import rateLimit from 'axios-rate-limit';

const globalApi = axios.create({ baseURL: 'https://api.discogs.com' });

globalApi.interceptors.request.use(request => {
    request.headers = {
        'Authorization': `Discogs key=${process.env.REACT_APP_DISCOGS_CONSUMER_KEY}, secret=${process.env.REACT_APP_DISCOGS_CONSUMER_SECRET}`
    }
    return request;
});

globalApi.interceptors.response.use(response => response, async (error) => {
    switch (error.response.status) {
        case 401:
            Cookies.remove(`${process.env.REACT_APP_LOGIN_COOKIE}`);
            break;
        default:
            break;
    }
    return Promise.reject(error);
});

const rateLimitGlobalApi = rateLimit(globalApi, { maxRequests: 1, perMilliseconds: 2000 });

// TODO: set type param to TSearchType
export const searchForReleaseBy = async (type: string, value: string): Promise<ICollectionItem[]> =>
    await rateLimitGlobalApi.get(`/database/search?${type}=${value}&format=Vinyl&type=release&per_page=100`).then(async (d: AxiosResponse<ISearchByResponse>) => {
        const uniqItems: ISearchByResults[] = await uniqByWithComparator(d.data.results, 'master_id', 0);
        return uniqItems.map(item => {
            const artist = item.title.split(' - ')[0];
            const title = item.title.split(' - ')[1];
            return {
                artist,
                title,
                year: parseInt(item.year),
                labels: item.label.join(', '),
                genres: item.genre.join(', '),
                catno: item.catno,
                image: item.cover_image,
                releaseId: item.id,
                instanceId: 0
            }
        })
    });

export const getPublicUserCollection = async (username: string, page: number): Promise<IUserCollection> =>
    await rateLimitGlobalApi.get(`/users/${username}/collection/folders/0/releases?page=${page}&per_page=500&sort=artist&sort_order=asc`).then((d: AxiosResponse<IUserCollectionResponse>) => {
        return d.data.releases.reduce((acc, release) => {
            const { basic_information } = release;
            const artist = basic_information.artists.map(artist => artist.name);
            const labels = basic_information.labels.map(label => label.name);
            const catno = basic_information.labels.map(label => label.catno);

            return {
                ...acc,
                items: [...acc.items, {
                    title: basic_information.title,
                    artist: removeDupsFromArray(artist).join(', '),
                    year: basic_information.year,
                    labels: removeDupsFromArray(labels).join(', '),
                    genres: basic_information.genres.join(', '),
                    catno: removeDupsFromArray(catno).join(', '),
                    releaseId: basic_information.id,
                    image: basic_information.cover_image,
                    instanceId: release.instance_id
                }]
            }
        }, {
            pages: d.data.pagination.pages,
            numberOfItems: d.data.pagination.items,
            items: [],
            value: ''
        } as IUserCollection);
    });