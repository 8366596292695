import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import logo from '../../assets/provinyl_primarylogo_darkbg.png';

export default makeStyles({
    root: {
        display: 'flex',
        alignItems: 'stretch',
        overflow: 'none',
        height: '100vh',
        backgroundImage: `url(${logo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#575366',
        backgroundSize: (props: IStyleProps) => `${props.window.innerWidth} ${props.window.innerHeight}`,
    }
});

interface IStyleProps {
    window: Window & typeof globalThis;
}