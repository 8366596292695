import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    title: { backgroundColor: theme.palette.primary.main, color: '#fff', userSelect: 'none' },
    content: { backgroundColor: theme.palette.background.default },
    actions: { backgroundColor: theme.palette.background.default },
    imageContainer: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'stretch'
    },
    information: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    modalLineColor: {
        textColor: theme.palette.text.secondary,
        color: theme.palette.secondary.main
    },
    modalTextField: {
        marginTop: theme.spacing()
    },
    modalInputFields: {
        display: 'flex',
        alignItems: 'baseline'
    },
    modalInputField: {
        marginLeft: theme.spacing() * .5
    },
    modalBulkInputField: {
        '& label.Mui-focused': {
            color: '#fff',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#fff',
            },
            '&:hover fieldset': {
                borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#fff',
            },
        },
        '& .MuiFormLabel-root': {
            color: '#fff'
        },
        marginLeft: theme.spacing() * .5,
        color: '#fff',
        width: theme.spacing() * 10
    },
    modalInputFieldTextColor: {
        color: '#fff',
    },
    modalTruncate: {
        width: '90%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    modalTruncateBusiness: {
        width: '40%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));