import axios from "axios";
import Cookies from 'js-cookie';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_SERVICES_URL}`,
    withCredentials: true
});

api.interceptors.response.use(response => response, async (error) => {
    switch (error.response.status) {
        case 401:
            Cookies.remove(`${process.env.REACT_APP_LOGIN_COOKIE}`);
            break;
        default:
            break;
    }
    return Promise.reject(error);
});

export default api;