import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@material-ui/core';
import useStyles from './style';

export default (props: IProps & DialogProps) => {
    const { title, content, children } = props;
    const classes = useStyles();

    return (
        <Dialog {...props} fullWidth>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogContent className={classes.content}>
                <DialogContentText color="textPrimary">{content}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.actions}>{children}</DialogActions>
        </Dialog>
    )
}

interface IProps {
    title: string;
    content: string;
}