import * as React from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { Card, CardActionArea, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core';
import memoize from "memoize-one";
import { isTablet } from "react-device-detect";
import { Icon } from '../base';
import { Add, Info } from '@material-ui/icons';
import { ICollectionItem } from '../../service/types';

const useStyles = makeStyles(theme => ({
    cardRootImage: {
        height: 350
    },
    cardRootImageTablet: {
        display: 'flex',
        width: '100%',
        height: 300,
        // marginBottom: theme.spacing(),
        backgroundColor: "#5762D5",
    },
    cardRootText: {
        display: 'flex',
        width: '100%',
        height: 160,
        marginBottom: theme.spacing(),
        // color: theme.palette.common.white,
        backgroundColor: "#5762D5",
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.common.white,
        flex: 1
    },
    cardContent: {
        flex: '1 0 auto',
    },
    cardCover: {
        width: isTablet ? 315 : 150,
    },
    list: {
        overflowX: 'hidden'
    },
    cardControls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.common.white,
    },
    icon: {
        color: theme.palette.common.white,
    },
    listRoot: {
        height: "100%",
        width: '100%',
        overflowX: 'hidden',
        overflow: 'none',
    },
}));

export default (props: IProps) => {
    const { data, listType, isNew, hasNextPage, itemsLoading, loadNextPage } = props;
    const ROW_SIZE: number = listType === 'image' ? 358 : 168;
    const classes = useStyles();
    const createItemData = memoize((classes, data, onClick, isNew) => ({
        classes,
        items: data,
        onClick,
        isNew
    }));
    const itemKey = (index: number, data: IData) => data.items[index].releaseId;
    const itemData: IData = createItemData(classes, data, props.onClick ? props.onClick : {}, isNew);
    const Item = (props: any) => {
        if (hasNextPage && props.index === data.length - 1 && !itemsLoading) loadNextPage && loadNextPage();
        return listType === 'image' ? <ImageItem {...props} /> : <TextItem {...props} />;
    };

    return (
        <div className={classes.listRoot}>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        className={classes.list}
                        height={height}
                        width={width}
                        itemCount={data.length}
                        itemSize={ROW_SIZE}
                        itemKey={itemKey}
                        itemData={itemData}
                    >
                        {Item}
                    </List>
                )}
            </AutoSizer>
        </div>
    )
}

const ImageItem = (props: {
    index: number;
    data: IData;
    style: any;
}) => {
    const classes = useStyles();
    const { data, index, style } = props;
    const item = data.items[index];

    return (
        <div>
            {!isTablet ? <div style={style}>
                <Card className={classes.cardRootImage}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            alt={item.title}
                            height="350"
                            image={item.image}
                            title={`${item.artist} - ${item.title}`}
                            onClick={() => (data.isNew) ? (data.onClick && data.onClick(item)) : window.open(`https://www.discogs.com/release/${item.releaseId}`, '_blank')}
                        />
                    </CardActionArea>
                </Card>
            </div> : <div style={style}>
                <Card className={classes.cardRootImageTablet}>
                    <div className={classes.cardDetails}>
                        <CardContent className={classes.cardContent}>
                            <Typography component="p" variant="body1" color="primary">
                                {item.title}
                            </Typography>
                            <Typography variant="subtitle1" color="primary">
                                <b>{item.artist}</b>
                            </Typography>
                        </CardContent>
                        <div className={classes.cardControls}>
                            <Typography variant="caption" color="primary">
                                <i>Cat No: {item.catno}</i>
                            </Typography>
                            <div>
                                {data.isNew && <Icon title="Add" color="primary" iconLabel={<Add />} onClick={() => data.onClick && data.onClick(item)} />}
                                <Icon title="Info" color="primary" iconLabel={<Info />} onClick={() => window.open(`https://www.discogs.com/release/${item.releaseId}`, '_blank')} />
                            </div>
                        </div>
                    </div>
                    <CardMedia
                        className={classes.cardCover}
                        image={item.image}
                        title={`${item.artist} - ${item.title}`}
                        onClick={() => window.open(`https://www.discogs.com/release/${item.releaseId}`, '_blank')}
                    />
                </Card>
            </div>}
        </div>
    )
}

const TextItem = (props: {
    index: number;
    data: IData;
    style: any;
}) => {
    const classes = useStyles();
    const { data, index, style } = props;
    const item = data.items[index];

    return (
        <div style={style}>
            <Card className={classes.cardRootText}>
                <div className={classes.cardDetails}>
                    <CardContent className={classes.cardContent}>
                        <Typography component="p" variant="body1" color="textSecondary">
                            {item.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            <b>{item.artist}</b>
                        </Typography>
                    </CardContent>
                    <div className={classes.cardControls}>
                        <Typography variant="caption" color="textSecondary">
                            <i>Cat No: {item.catno}</i>
                        </Typography>
                        <div>
                            {data.isNew && <Icon title="Add" style={{ color: '#fff' }} iconLabel={<Add />} onClick={() => data.onClick && data.onClick(item)} />}
                            <Icon title="Info" style={{ color: '#fff' }} iconLabel={<Info />} onClick={() => window.open(`https://www.discogs.com/release/${item.releaseId}`, '_blank')} />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

interface IProps {
    data: ICollectionItem[];
    listType: 'image' | 'text';
    isNew?: boolean;
    hasNextPage?: boolean;
    itemsLoading?: boolean;
    loadNextPage?: () => void;
    onClick?: (item: ICollectionItem) => void;
}

interface IData {
    classes: any;
    items: ICollectionItem[];
    onClick?: (item: ICollectionItem) => void;
    isNew?: boolean;
}