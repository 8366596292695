import React from 'react';
import { Popover, TextField, Typography } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import useStyles from './style';

export default (props: IProps) => {
    const { open, anchorEl, username, onClose, onClick } = props;
    const classes = useStyles();

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={classes.popover}
        >
            <div className={classes.content}>
                <TextField
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        endAdornment: <FileCopy className={classes.icon} onClick={onClick} />
                    }}
                    margin='dense'
                    color='secondary'
                    variant='outlined'
                    value={`${process.env.REACT_APP_CLIENT_URL}/View/${username}`}
                />
                <Typography variant='body2'>To share your collection, please login into Discogs and follow these steps... <br />
                    <a target="_blank" rel="noopener noreferrer" href='https://www.discogs.com/settings/privacy' style={{ textDecoration: 'none' }}>
                        Account -&gt; Settings -&gt; Privacy -&gt; <b>Allow others to browse my collection.</b> (Click here)</a></Typography>
            </div>
        </Popover>
    )
}

interface IProps {
    username: string;
    open: boolean;
    anchorEl: Element | undefined;
    onClose: () => void;
    onClick: () => void;
}