import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    title: { backgroundColor: theme.palette.primary.main, color: '#fff', userSelect: 'none' },
    content: { backgroundColor: theme.palette.background.default },
    actions: { backgroundColor: theme.palette.background.default },
    information: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    search: {
        display: 'flex',
        flexDirection: 'row'
    },
    searchBox: { flex: 1 },
    searchType: {
        marginLeft: theme.spacing(),
        width: '40%'
    },
    disableSelection: {
        userSelect: 'none'
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(),
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(),
        alignItems: 'center'
    },
    textTruncate: {
        width: '85%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));