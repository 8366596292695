import * as React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { History } from 'history';
import { DiscogsIcon } from '../../component/base';
import logo from '../../assets/provinyl_primary_D1E3DD_logo.png';
import useStyles from './style';
import Cookies from 'js-cookie';

export default (props: IProps) => {
    const classes = useStyles();
    const { history } = props;

    React.useEffect(() => {
        const cookie = Cookies.get(`${process.env.REACT_APP_LOGIN_COOKIE}`) || '';
        if (cookie !== undefined && cookie !== null && cookie.length > 0) history.push('/Collection');
    }, []);
    
    return (
        <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.login}>
                        <img src={logo} className={classes.logo} alt='Welcome to ProVinyl' />
                        <Button startIcon={<DiscogsIcon />} className={classes.discogLogin} size='large' variant='outlined'
                            href={`${process.env.REACT_APP_SERVICES_URL}/auth/discogs/${Math.floor(Math.random() * 1000)}`}>
                            Login with Discogs
                        </Button>
                        {/* <div className={classes.terms}>
                            <Typography variant={'body2'} className={classes.link}>By signing up, you agree to our <a target="_blank" rel="noopener noreferrer"
                                href={`https://app.termly.io/document/terms-of-use-for-saas/2094c543-15c8-4f36-9ab2-992503f9bc81`}>terms and conditions.</a></Typography>
                        </div> */}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

interface IProps {
    history: History<any>;
}