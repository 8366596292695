import * as React from 'react';
import { CardContent, Button, Typography, Card, CardActions, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        backgroundColor: '#5762D5',
        marginBottom: theme.spacing()
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    iconHeader: {
        display: 'flex',
        alignContent: 'space-between'
    },
    buttonBar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonBarWithText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    }
}));

export default (props: IProps) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <div className={classes.iconHeader}>
                    {props?.icon}
                    <Typography variant="body1" color="primary">
                        <b>{props.title}</b>
                    </Typography>
                </div>
                {props.content}
            </CardContent>
            <CardActions className={props.miscText ? classes.buttonBarWithText : classes.buttonBar}>
                {props.miscText && <Typography variant="subtitle2" color="primary"><i>{props.miscText}</i></Typography>}
                <div>
                    {props.buttons?.filter(button => button.buttonText !== undefined).map((button, index) => <Button key={index} style={{ marginLeft: 5 }} size="small" color={'primary'} variant={index === 1 ? 'text' : 'outlined'} onClick={button.buttonAction}>{button.buttonText}</Button>)}
                </div>
            </CardActions>
        </Card>
    )
}

interface IProps {
    title: string;
    content: JSX.Element;
    icon?: JSX.Element;
    buttons?: {
        buttonAction?: () => void;
        buttonText?: string;
    }[];
    // buttonText?: string;
    miscText?: string;
    // buttonAction?: () => void;
}