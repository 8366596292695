import { makeStyles } from '@material-ui/core';

export default makeStyles({
    root: {
        height: window.innerHeight,
        backgroundColor: '#D1E3DD',
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    logo: {
        height: '38%',
    },
    terms: {
        display: 'flex',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    discogLogin: {
        marginBottom: 10,
        backgroundColor: 'black',
        width: '80%',
        textAlign: 'center',
        color: 'white',
        '&:hover': {
            backgroundColor: 'grey'
        }
    },
});