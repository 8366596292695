import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, MenuItem, Typography, Button, Paper } from '@material-ui/core';
import { Select, Searchbox } from '@jayjonesdev/react-material-ui-library';
import { useSnackbar } from 'notistack';
import useStyles from './style';
import { getReleaseInformation } from '../../service/discog.service';
import { ICollectionItem, IDetailedReleaseInformation, TSearchType } from '../../service/types';
import { searchForReleaseBy } from '../../service/database.service';

// TODO: Support Pagination
export default (props: IProps & DialogProps) => {
    const classes = useStyles();
    const { close, setItem, viewItemDialog } = props;
    const [searching, setSearching] = React.useState<boolean>(false);
    const [searchValue, setSearchValue] = React.useState<string>('');
    const [dropDownValue, setDropDownValue] = React.useState<string>('');
    const [searchText, setSearchText] = React.useState<string>('');
    const [items, setItems] = React.useState<ICollectionItem[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const dialogProps = (): DialogProps => {
        let { setItem, viewItemDialog, close, ...rest } = props;
        return rest;
    }
    const open = dialogProps().open;

    React.useEffect(() => {
        reset();
    }, [open]);

    const dropDownOptions: {
        value: TSearchType;
        label: string;
    }[] = [
        {
            value: 'catno',
            label: 'Catalog #',
        }, {
            value: 'barcode',
            label: 'Barcode'
        }, {
            value: 'title',
            label: 'Album Title'
        }, {
            value: 'artist',
            label: 'Artist'
        }, {
            value: 'track',
            label: 'Track'
        }
    ];

    const onClose = () => {
        close();
        reset();
    };
    const reset = () => {
        setItems([]);
        setDropDownValue('');
        setSearchValue('');
        setSearchText('');
    }
    const viewItem = async (item: ICollectionItem) => {
        setLoading(true);
        try {
            const release = await getReleaseInformation(item.releaseId);
            setItem(release);
            viewItemDialog(true);
            setLoading(false);
        } catch (e) { enqueueSnackbar(`Unable to find record.`, { variant: 'error' }); setLoading(false); }
    };
    const submit = (event: React.KeyboardEvent<HTMLDivElement>) => event.key === 'Enter' || event.keyCode === 13 ? search() : {};
    const search = async () => {
        let items = [];
        setSearching(true);

        try {
            items = await searchForReleaseBy(dropDownValue, searchValue);
            setItems(items);
            if (items.length === 0) enqueueSnackbar(`No records found matching that criteria.`, { variant: 'warning' });
        } catch (e) { enqueueSnackbar(`Error searching for record.`, { variant: 'error' }); }
        setSearching(false);
    };
    const onDropDownChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDropDownValue(e.target.value);
        switch (e.target.value) {
            case 'catno':
                setSearchText('catalog number');
                break;
            case 'barcode':
                setSearchText('barcode');
                break;
            case 'artist':
                setSearchText('artist');
                break;
            case 'title':
                setSearchText('title');
                break;
            case 'track':
                setSearchText('track');
                break;
            default:
                break;
        }
    }
    const onClear = () => {
        setSearchValue('');
        setItems([]);
    }

    return (
        <Dialog {...dialogProps()} onBackdropClick={onClose} onClose={onClose} fullWidth>
            <DialogTitle className={classes.title}>Add By Type</DialogTitle>
            <DialogContent className={classes.content}>
                <div className={classes.search}>
                    <Searchbox autoFocus onKeyUp={submit} onClear={onClear} className={classes.searchBox} disabled={searching} variant={'outlined'} value={searchValue} placeholder={dropDownValue !== '' ? `Search by ${searchText}...` : 'Select search type'} onChange={(e) => setSearchValue(e.target.value)} />
                    <Select className={classes.searchType} onKeyUp={submit} variant='outlined' readOnly={searching} value={dropDownValue} onChange={onDropDownChange} helperText={<Typography className={classes.disableSelection} variant='body2' color='primary'>Please select your search type</Typography>}>
                        {dropDownOptions.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                    </Select>
                </div>
                {!loading ? items.map((item, index) => <Paper variant='elevation' key={index} className={classes.item}>
                    <Typography variant='subtitle1' color={'primary'} title={item.title} className={classes.textTruncate}>{item.artist} - {item.title}</Typography>
                    <Button disabled={searching || loading} variant='outlined' color='primary' onClick={() => viewItem(item)}>View</Button>
                </Paper>) : <Typography variant={'subtitle1'}>Fetching release data...</Typography>}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button variant='outlined' color='secondary' disabled={dropDownValue.length === 0 || searchValue.length === 0 || searching} onClick={search}>{searching ? 'Searching' : 'Search'}</Button>
                <Button color="primary" variant='contained' onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface IProps {
    setItem: (item: IDetailedReleaseInformation) => void;
    close: () => void;
    viewItemDialog: (isNew: boolean) => void;
}