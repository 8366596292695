import React from 'react';
import { Button, Grow, Typography, CircularProgress } from '@material-ui/core';
import { ButtonBar, Searchbox, Divider, Table, LoadingOverlay } from '@jayjonesdev/react-material-ui-library';
import { ViewList, ViewDay } from '@material-ui/icons';
import { Dialog, ItemDialog, TileGrid, Toolbar } from '../../component';
import useStyles from './style';
import { columns as tableColumns } from './tableData';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { getReleaseInformation } from '../../service/discog.service';
import { ICollectionItem, IDetailedReleaseInformation, IUserCollection } from '../../service/types';
import { History } from 'history';
import { useParams } from 'react-router-dom';
import { getPublicUserCollection } from '../../service/database.service';
import { ITableColumn } from '../Collection/tableData';
import { sortTableData, uniqByWithComparator } from '../../service/util.service';

export default (props: IProps) => {
    const { history } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [dialogs, setDialogs] = React.useState({
        logout: false,
        item: false,
    });
    const [item, setItem] = React.useState<IDetailedReleaseInformation>();
    const [viewType, setViewType] = React.useState<'text' | 'image'>('image');
    const [searchValue, setSearchValue] = React.useState('');
    const [columns, setColumns] = React.useState(tableColumns);
    const [data, setData] = React.useState<ICollectionItem[]>([]);
    const [loading, setLoading] = React.useState({
        value: true,
        label: 'Loading collection...'
    });
    const [collection, setCollection] = React.useState<IUserCollection>({
        pages: 0,
        numberOfItems: 0,
        items: [],
        value: ''
    })
    const { enqueueSnackbar } = useSnackbar();
    const { username } = useParams<{ username: string }>();
    const [searchDisabled, setSearchDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        setLoading({
            value: true,
            label: 'Loading collection...'
        });
        fetchUserCollection(username);
    }, []);

    React.useEffect(() => {
        search(searchValue);
    }, [searchValue]);

    const fetchUserCollection = async (username: string) => {
        let userCollection: IUserCollection = {
            pages: 0,
            numberOfItems: 0,
            items: [],
            value: ''
        };
        try {
            await getPublicUserCollection(username, 1).then(async (d) => {
                userCollection = d;
                userCollection.items = await uniqByWithComparator(userCollection.items, 'releaseId', 0);
            }).finally(async () => {
                setCollection(userCollection);
                setLoading({ ...loading, value: false });
                setData(userCollection.items);
                if (1 < userCollection.pages) await fetchPage(username, 2, userCollection);
                else setSearchDisabled(false);
            });
        } catch (e) {
            setLoading({ ...loading, value: false });
            enqueueSnackbar('Unable to load collection. Please tell user to enable collection sharing.', { variant: 'error' });
        }
    }
    const fetchPage = async (username: string, page: number, userCollection: IUserCollection) => {
        const { pages, items } = userCollection;
        let newCollection = userCollection;

        await getPublicUserCollection(username, page).then(async (d) => {
            const updatedItems = await uniqByWithComparator([...items, ...d.items], 'releaseId', 0);
            newCollection = {
                ...userCollection,
                items: updatedItems
            }
            setCollection(newCollection);
            setData(updatedItems);
        }).finally(() => {
            if (page < pages) {
                const nextPage = page + 1;
                fetchPage(username, nextPage, newCollection);
            } else setSearchDisabled(false);
        });
    }
    const navigateToLogin = () => history.push('/Login');
    const handleMenuButton = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const toggleDialog = (dialog: 'logout' | 'item') => {
        if (dialogs[dialog]) setAnchorEl(null);

        setDialogs({
            ...dialogs,
            [dialog]: !dialogs[dialog]
        });
    };
    const onColumnClick = (column: ITableColumn) => {
        const updatedColumns = columns.map(currentColumn => {
            if (currentColumn.label !== column.label) currentColumn.sort = null;
            else currentColumn.sort = currentColumn.sort === null ? 'ASC' : currentColumn.sort === 'ASC' ? 'DESC' : null;
            return currentColumn;
        });
        setColumns(updatedColumns);
        const data = sortTableData(updatedColumns, searchValue, collection.items);
        setData(data);
    };
    const onRowClick = (item: ICollectionItem) => {
        setLoading({ value: true, label: 'Fetching release data...' });
        try {
            getReleaseInformation(item.releaseId).then(info => {
                setItem({
                    ...info,
                    image: item!.image
                });
                setDialogs({
                    ...dialogs,
                    item: true
                });
            }).finally(() => setLoading({ ...loading, value: false }));
        } catch (e) {
            setLoading({ ...loading, value: false });
            enqueueSnackbar('Unable to fetch release data.', { variant: 'error' });
        }
    }
    const search = (search: string) => {
        const data = sortTableData(columns, search, collection.items);
        setData(data);
    };
    const logout = () => {
        Cookies.remove(`${process.env.REACT_APP_LOGIN_COOKIE}`);
        history.push('/Login');
    }
    const onSearchBoxChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchValue(e.target.value);
    const onSearchBoxClear = () => setSearchValue('');
    const onToolbarMenuClose = () => setAnchorEl(null);

    return (
        <div className={classes.root}>
            <Toolbar anchorEl={anchorEl} onClose={onToolbarMenuClose} onMenuButtonClick={handleMenuButton} onLogoClick={navigateToLogin} isPublic={true} />
            <div className={classes.content}>
                <div className={classes.buttonBar}>
                    <Searchbox className={classes.searchBox} placeholder={searchDisabled ? 'Loading collection...' : `Search collection...`} color='primary' value={searchValue}
                        onChange={onSearchBoxChange} onClear={onSearchBoxClear} variant='outlined' disabled={searchDisabled} />
                    <ButtonBar>
                        <Button startIcon={viewType === 'image' ? <ViewList /> : <ViewDay />} size='large' variant={'contained'} color={'primary'} onClick={() => setViewType(viewType === 'image' ? 'text' : 'image')}>{viewType === 'image' ? 'View List' : 'View Tile'}</Button>
                    </ButtonBar>
                </div>
                <Divider color='primary' size={2} />
                {<div className={classes.tableContainer}>
                    {searchValue.length > 0 && data.length === 0 && <Typography variant={'subtitle1'}>No search results.</Typography>}
                    {!loading && data.length === 0 && searchValue.length === 0 && <Typography variant={'subtitle1'}>Please add items to your library.</Typography>}
                    {(data.length > 0 && viewType === 'text') && <Table onRowClick={onRowClick} columns={columns} onColumnClick={onColumnClick} key='releaseId' data={data} />}
                    {(data.length > 0 && viewType === 'image') && <TileGrid onClick={onRowClick} imageKey={'image'} data={data} />}
                </div>}
            </div>
            <Dialog open={dialogs.logout} title='Logout' content='Are you sure you want to logout?' onBackdropClick={() => toggleDialog('logout')}>
                <>
                    <Button color="primary" variant='outlined' onClick={() => toggleDialog('logout')}>Cancel</Button>
                    <Button color="primary" variant='contained' onClick={logout}>Logout</Button>
                </>
            </Dialog>
            <ItemDialog isNew={false} exists={false} open={dialogs.item} item={item} removeItem={() => { }}
                addItem={() => { }} close={() => toggleDialog('item')} onBackdropClick={() => toggleDialog('item')} />
            <LoadingOverlay open={loading.value} type='circular' color="primary" label={loading.label} />
        </div>
    )
};

interface IProps {
    history: History<any>;
}