import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: '#D1E3DD'
        },
        primary: {
            main: '#6E7DAB'
        },
        secondary: {
            light: '#575366',
            main: '#32292F',
            contrastText: '#fff',
        },
        text: {
            primary: '#546392',
            secondary: '#fff'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: ['Avenir', 'Roboto'].join(','),
        // htmlFontSize: 14,
        // fontSize: 14,
        button: {
            textTransform: 'none'
        }
    }
});
//https://coolors.co/32292f-575366-6e7dab-5762d5-d1e3dd