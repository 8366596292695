import React from 'react';
import { History } from 'history';
import useStyles from './style';
import { Dialog } from '../../component';

export default (props: IProps) => {
    const classes = useStyles(window);
    const { title, message, history } = props;
    
    React.useEffect(() => {
        setTimeout(() => {
            history.push('/Login');
        }, 3500);
    });

    return (
        <div className={classes.root}>
            <Dialog open={true} title={title} content={message} onBackdropClick={() => {}}/>
        </div>
    )
}

interface IProps {
    history: History<any>;
    message: string;
    title: string;
}