import * as React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    buttonRoot: {
        marginLeft: theme.spacing()
    }
}));

export default (props: IProps) => {
    const classes = useStyles();
    const { text } = props;
    return (<Button className={classes.buttonRoot} {...props}>{text}</Button>);
}

interface IProps {
    text: string | React.ReactNode;
    onClick?: () => void;
    variant?: "text" | "outlined" | "contained" | undefined;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    style?: any;
    href?: string;
    size?: "medium" | "large" | "small";
    type?: "button" | "submit" | "reset" | undefined;
    color?: 'primary' | 'secondary';
    disabled?: boolean;
    className?: string;
    fullWidth?: boolean;
}