import * as React from 'react';
import { TextField, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(),
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        minWidth: '10em'
    }
}));

export default (props: IProps) => {
    const classes = useStyles();
    return (
        <TextField
            {...props}
            className={classes.root}
            id="outlined-select"
            select
            variant="outlined"
        >
            {props.options.map((option, index) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )
}

interface IProps {
    value: string;
    size?: 'small' | 'medium';
    label?: string;
    placeholder?: string;
    onChange: (e: any) => void;
    options: IOption[];
    fullWidth?: boolean;
    helperText?: string;
    disabled?: boolean;
    className?: any;
    onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

interface IOption {
    label: string;
    value: string;
}