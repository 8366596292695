import * as React from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { Card, CardActionArea, CardMedia, makeStyles } from '@material-ui/core';
import memoize from "memoize-one";
import { ICollectionItem } from '../../service/types';

const useStyles = makeStyles(theme => ({
    cardRoot: {
        width: 300
    },
    cardRootImage: {
        display: 'flex',
        width: '100%',
        height: 300,
        marginBottom: theme.spacing(),
        backgroundColor: "#5762D5",
    },
    cardRootText: {
        display: 'flex',
        width: '100%',
        height: 160,
        marginBottom: theme.spacing(),
        backgroundColor: "#5762D5",
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    cardContent: {
        flex: '1 0 auto',
    },
    cardCover: {
        width: 300,
    },
    list: {
        overflowX: 'hidden'
    },
    cardControls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    listRoot: {
        height: "100%",
        width: '100%'
    },
}));

export default (props: IProps) => {
    const { data } = props;
    const ROW_SIZE: number = 300;
    const classes = useStyles();
    const createItemData = memoize((classes, data, onClick) => ({
        classes,
        items: data,
        onClick
    }));
    const itemData = createItemData(classes, data, props.onClick);

    return (
        <div className={classes.listRoot}>
            <AutoSizer>
                {({ height, width }) => {
                    const columnCount = data.length <= Math.floor(width / ROW_SIZE) ? data.length : Math.floor(width / ROW_SIZE);
                    const columnWidth = Math.floor((width - ROW_SIZE) / (columnCount - 1));
                    const itemKey = (params: {
                        columnIndex: number;
                        rowIndex: number;
                        data: IData;
                    }) => {
                        const { rowIndex, columnIndex, data } = params;
                        let index: number;
                        if (data.items.length <= columnCount - 1) index = columnIndex;
                        else index = (rowIndex * columnCount) + (columnIndex);
                        return data.items[index]?.releaseId || -1;
                    };
                    
                    return (
                        <Grid
                            height={height}
                            width={width}
                            columnCount={columnCount}
                            rowCount={Math.ceil(itemData.items.length / columnCount)}
                            columnWidth={(index: number) => (index < (columnCount - 1)) ? columnWidth : ROW_SIZE}
                            rowHeight={(index: number) => columnWidth}
                            estimatedRowHeight={columnWidth}
                            itemData={itemData}
                            itemKey={itemKey}
                        >
                            {(props: {
                                data: any;
                                style: any;
                                columnIndex: number;
                                rowIndex: number;
                            }) => <ImageItem {...props} columnCount={columnCount} />}
                        </Grid>
                    )
                }}
            </AutoSizer>
        </div>
    )
}

const ImageItem = (props: {
    columnIndex: number,
    rowIndex: number,
    data: any;
    style: any;
    columnCount: number;
}) => {
    const classes = useStyles();
    const { data, rowIndex, columnIndex, columnCount, style } = props;
    let index: number;
    if (data.items.length <= columnCount) index = columnIndex;
    else index = (rowIndex * columnCount) + (columnIndex);
    const item = data.items[index];

    if (item === undefined) return (null);
    else return (
        <div style={style}>
            <Card className={classes.cardRoot}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        alt={item.title}
                        height="300"
                        image={item.image}
                        title={`${item.artist} - ${item.title}`}
                        onClick={() => data.onClick(item)}
                    />
                </CardActionArea>
            </Card>
        </div>
    )
}

interface IProps {
    data: ICollectionItem[];
    onClick: (item: ICollectionItem) => void;
}

interface IData {
    classes: any;
    items: ICollectionItem[];
    onClick: (item: ICollectionItem) => void;
}