export const columns: ITableColumn[] = [{
    label: "Album",
    dataKey: "title",
    width: '28%',
    sort: null
},
{
    label: "Artist",
    dataKey: "artist",
    width: '12.5%',
    sort: null
},
{
    label: "Year",
    dataKey: "year",
    width: '5%',
    numeric: true,
    sort: null
},
{
    label: "Labels",
    dataKey: "labels",
    width: '17%',
    sort: null
},
{
    label: "Genres",
    dataKey: "genres",
    width: '9.5%',
    sort: null
},
{
    label: "Catalog #",
    dataKey: "catno",
    width: '10%',
    sort: null
}];

export interface ITableColumn {
    label: string;
    dataKey: string;
    numeric?: boolean;
    width: string;
    sort: 'ASC' | 'DESC' | null;
}