import * as React from 'react';
import { IconButton, Grow, CircularProgress } from '@material-ui/core';
import { MobileList } from '../../../component/complex';
import icon from '../../../assets/provinyl_logomark_6E7DAB_icon.png';
import { History } from 'history';
import { SortByAlpha, ViewDay, ViewList, Home } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { ICollectionItem, IUserCollection } from '../../../service/types';
import { Appbar, ButtonBar, Searchbox, Divider, LoadingOverlay } from '@jayjonesdev/react-material-ui-library';
import useStyles from './style';
import { useParams } from 'react-router-dom';
import { getPublicUserCollection } from '../../../service/database.service';
import { uniqByWithComparator } from '../../../service/util.service';

const PublicCollection: React.FC<IProps> = (props) => {
    const { history } = props;
    const { username } = useParams<{ username: string }>();
    const [searchString, setSearchString] = React.useState('');
    const [searchByProps, setSearchByProps] = React.useState<{
        searchPlaceholder: string;
        searchBy: string;
    }>({
        searchPlaceholder: 'Search...',
        searchBy: ''
    });
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [viewType, setViewType] = React.useState<'text' | 'image'>('image');
    const [sortDirection, setSortDirection] = React.useState<'ASC' | 'DESC'>('ASC');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<ICollectionItem[]>([]);
    const [collection, setCollection] = React.useState<IUserCollection>({
        pages: 0,
        numberOfItems: 0,
        items: [],
        value: ''
    });
    const [searchDisabled, setSearchDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        setLoading(true);
        try {
            fetchUserCollection(username);
        } catch (e) {
            setLoading(false);
            enqueueSnackbar('Unable to load collection. Please tell user to enable collection sharing.', { variant: 'error' });
        }
    }, []);

    React.useEffect(() => {
        search(searchString);
    }, [searchString, sortDirection]);

    const search = (searchString: string) => {
        let sortedData = searchString.length > 0 ? collection.items.filter(item => item.artist.toLowerCase().includes(searchString.toLowerCase())
            || item.title.toLowerCase().includes(searchString.toLowerCase())
            || item.labels.toLowerCase().includes(searchString.toLowerCase())
            || item.catno.toLowerCase().includes(searchString.toLowerCase())
            || item.genres.toLowerCase().includes(searchString.toLowerCase())) : collection.items;

        sortedData.sort((a, b) => {
            switch (sortDirection) {
                case 'ASC':
                    if (a.artist.toLowerCase() > b.artist.toLowerCase()) return 1;
                    if (b.artist.toLowerCase() > a.artist.toLowerCase()) return -1;
                    return 0;
                case 'DESC':
                    if (b.artist.toLowerCase() > a.artist.toLowerCase()) return 1;
                    if (a.artist.toLowerCase() > b.artist.toLowerCase()) return -1;
                    return 0;
                default:
                    if (a.artist.toLowerCase() > b.artist.toLowerCase()) return 1;
                    if (b.artist.toLowerCase() > a.artist.toLowerCase()) return -1;
                    return 0;
            }
        });
        setData(sortedData);
    };
    const fetchUserCollection = (username: string) => {
        let userCollection: IUserCollection = {
            pages: 0,
            numberOfItems: 0,
            items: [],
            value: ''
        };
        getPublicUserCollection(username, 1).then(async (d) => {
            userCollection = d;
            userCollection.items = await uniqByWithComparator(userCollection.items, 'releaseId', 0);
        }).finally(async () => {
            setCollection(userCollection);
            setLoading(false);
            setData(userCollection.items);
            if (1 < userCollection.pages) await fetchPage(username, 2, userCollection);
            else setSearchDisabled(false);
        });
    }
    const fetchPage = async (username: string, page: number, userCollection: IUserCollection) => {
        const { pages, items } = userCollection;
        let newCollection = userCollection;

        await getPublicUserCollection(username, page).then(async (d) => {
            const updatedItems = await uniqByWithComparator([...items, ...d.items], 'releaseId', 0);
            newCollection = {
                ...userCollection,
                items: updatedItems
            }
            setCollection(newCollection);
            setData(updatedItems);
        }).finally(() => {
            if (page < pages) {
                const nextPage = page + 1;
                fetchPage(username, nextPage, newCollection);
            } else setSearchDisabled(false);
        });
    }
    const navigateToLogin = () => history.push('/Login');
    const onSearchBoxClear = () => setSearchString('');
    const onSearchBoxChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchString(e.target.value);
    const sort = () => setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');

    return (
        <div className={classes.root}>
            <>
                <Appbar color='primary' className={classes.appbar}>
                    <img className={classes.logo} src={icon} alt="logo" onClick={navigateToLogin} />
                    <ButtonBar>
                        <IconButton
                            aria-label="home action"
                            aria-haspopup="false"
                            onClick={navigateToLogin}
                            color="inherit"
                            title='Home'
                        >
                            <Home />
                        </IconButton>
                    </ButtonBar>
                </Appbar>
                <div className={classes.toolbar} />
            </>
            <div className={classes.data}>
                <div className={classes.searchBar}>
                    <Searchbox className={classes.searchBox} placeholder={searchDisabled ? 'Loading...' : searchByProps.searchPlaceholder} color='primary' value={searchString}
                        onChange={onSearchBoxChange} onClear={onSearchBoxClear} variant='outlined' disabled={searchDisabled} />
                    <div className={classes.icons}>
                        <IconButton
                            aria-label="sort action"
                            aria-haspopup="false"
                            onClick={sort}
                            color="inherit"
                            title='Sort By Artist'
                        >
                            <SortByAlpha />
                        </IconButton>
                        {viewType === 'image' &&
                            <IconButton
                                aria-label="text view type switch action"
                                aria-haspopup="false"
                                onClick={() => setViewType('text')}
                                color="inherit"
                                title='view type to text'
                            >
                                <ViewList />
                            </IconButton>}
                        {viewType === 'text' && <IconButton
                            aria-label="image view type switch action"
                            aria-haspopup="false"
                            onClick={() => setViewType('image')}
                            color="inherit"
                            title='view type to image'
                        >
                            <ViewDay />
                        </IconButton>}
                    </div>
                </div>
                <Divider color='primary' size={2} />
                <MobileList data={data} listType={viewType} onClick={() => { }} />
            </div>
            <LoadingOverlay open={loading} type='circular' color="primary" label='' />
        </div>
    )
}

export default PublicCollection;

interface IProps {
    history: History<any>;
}