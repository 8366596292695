import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Typography } from '@material-ui/core';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
        if (React.isValidElement(child) && child.type === ListSubheader) return 48;
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) return 8 * itemSize;
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    key={itemCount}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const useStyles = makeStyles({
    listbox: {
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const renderGroup = (params: any) => [
    <ListSubheader key={params.idMusic} component="div">
        {params.key}
    </ListSubheader>,
    params.children,
];

export default (props: IProps) => {
    const classes = useStyles();
    const ref = React.useRef(null);
    const [val, setVal] = React.useState<string>('');
    const [inputVal, setInputVal] = React.useState<undefined | string>(undefined);

    return (
        <Autocomplete
            id={props.id}
            ref={ref}
            fullWidth
            disableListWrap
            clearOnBlur
            clearOnEscape
            onChange={(event, value) => {
                if (value !== null) {
                    props.valueSelected(value);
                }
            }}
            onInputChange={(event, string, reason) => {
                if (reason === 'reset') {
                    setVal('');
                    (ref.current as any).querySelector('button').click();
                }
                else setVal(string);
            }}
            inputValue={val}
            classes={classes}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            renderGroup={renderGroup}
            options={props.options}
            getOptionLabel={(option) => `${option.title} - ${option.artist}`}
            renderInput={(params) => <TextField {...params} value={val} color='secondary' placeholder={'Search'} label="Search for item" variant="outlined" />}
        />
    );
}
interface IProps {
    options: any[];
    id: string;
    valueSelected: (value: any) => void;
}