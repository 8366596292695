import React from 'react';
import { Button, Grow, Typography } from '@material-ui/core';
import { ButtonBar, Searchbox, Divider, Table, NavDropdownMenuItem, LoadingOverlay } from '@jayjonesdev/react-material-ui-library';
import { ViewList, ViewDay, Add, LibraryAdd } from '@material-ui/icons';
import { Dialog, ItemDialog, TileGrid, AddByTypeDialog, InformationDialog, Toolbar } from '../../component';
import useStyles from './style';
import { columns as tableColumns, ITableColumn } from './tableData';
import { useSnackbar } from 'notistack';
import { addToUserCollection, getCollectionValue, getReleaseInformation, getUserCollection, getUserIdentity, removeFromUserCollection } from '../../service/discog.service';
import { ICollectionItem, IDetailedReleaseInformation, IUserCollection } from '../../service/types';
import findIndex from 'lodash.findindex';
import { History } from 'history';
import BarcodeReader from 'react-barcode-reader';
import { searchForReleaseBy } from '../../service/database.service';
import { sortTableData, uniqByWithComparator } from '../../service/util.service';
import Cookies from 'js-cookie';
import { AuthService } from '../../service';
import ShareableLinkPopover from '../../component/shareableLinkPopover';

export default (props: IProps) => {
    const { history } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [popoverAnchor, setPopoverAnchor] = React.useState<undefined | HTMLElement>();
    const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
    const [dialogs, setDialogs] = React.useState({
        info: false,
        logout: false,
        remove: false,
        item: false,
        addbyType: false
    });
    const [item, setItem] = React.useState<IDetailedReleaseInformation>();
    const [viewType, setViewType] = React.useState<'text' | 'image'>('image');
    const [searchValue, setSearchValue] = React.useState('');
    const [columns, setColumns] = React.useState(tableColumns);
    const [data, setData] = React.useState<ICollectionItem[]>([]);
    const [loading, setLoading] = React.useState({
        value: true,
        label: 'Loading collection...'
    });
    const [isNew, setIsNew] = React.useState<boolean>(false);
    const [exists, setExists] = React.useState<boolean>(false);
    const [username, setUsername] = React.useState<string>('');
    const [collection, setCollection] = React.useState<IUserCollection>({
        pages: 0,
        numberOfItems: 0,
        items: [],
        value: ''
    });
    const { enqueueSnackbar } = useSnackbar();
    const [searchDisabled, setSearchDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        setLoading({
            value: true,
            label: 'Loading your collection...'
        });
        try {
            getUserIdentity().then(identity => {
                setUsername(identity.username);
                fetchUserCollection(identity.username);
            }).catch(e => {
                setLoading({ ...loading, value: false });
                enqueueSnackbar('Authentication error, please login again.', { variant: 'error' });
                logout();
            });

        } catch (e) {
            setLoading({ ...loading, value: false });
            enqueueSnackbar('Unable to load collection.', { variant: 'error' });
        }
    }, []);

    React.useEffect(() => {
        search(searchValue);
    }, [searchValue]);

    const fetchUserCollection = (username: string) => {
        let userCollection: IUserCollection = {
            pages: 0,
            numberOfItems: 0,
            items: [],
            value: ''
        };
        getUserCollection(username, 1).then(async (d) => {
            userCollection = d;
            userCollection.items = await uniqByWithComparator(userCollection.items, 'releaseId', 0);
            userCollection.value = await getCollectionValue(username).then(value => value.median);
        }).finally(async () => {
            setCollection(userCollection);
            setData(userCollection.items);
            setLoading({ ...loading, value: false });
            if (1 < userCollection.pages) await fetchPage(username, 2, userCollection);
            else setSearchDisabled(false);
        });
    }
    const fetchPage = async (username: string, page: number, userCollection: IUserCollection) => {
        const { pages, items } = userCollection;
        let newCollection = userCollection;

        await getUserCollection(username, page).then(async (d) => {
            const updatedItems = await uniqByWithComparator([...items, ...d.items], 'releaseId', 0);
            newCollection = {
                ...userCollection,
                items: updatedItems
            }
            setCollection(newCollection);
            setData(updatedItems);
        }).finally(() => {
            if (page < pages) {
                const nextPage = page + 1;
                fetchPage(username, nextPage, newCollection);
            } else setSearchDisabled(false);
        });
    }
    const handleMenuButton = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const toggleDialog = (dialog: 'info' | 'logout' | 'item' | 'addbyType' | 'remove') => {
        if (dialogs[dialog]) setAnchorEl(null);

        setDialogs({
            ...dialogs,
            [dialog]: !dialogs[dialog]
        });
    };
    const onColumnClick = (column: ITableColumn) => {
        const updatedColumns = columns.map(currentColumn => {
            if (currentColumn.label !== column.label) currentColumn.sort = null;
            else currentColumn.sort = currentColumn.sort === null ? 'ASC' : currentColumn.sort === 'ASC' ? 'DESC' : null;
            return currentColumn;
        });
        setColumns(updatedColumns);
        const data = sortTableData(updatedColumns, searchValue, collection.items);
        setData(data);
    };
    const onRowClick = (item: ICollectionItem) => {
        setLoading({ value: true, label: 'Fetching release data...' })
        try {
            getReleaseInformation(item.releaseId).then(info => {
                setItem(info);
                setDialogs({
                    ...dialogs,
                    item: true
                });
                setIsNew(false);
                setExists(true);
            }).finally(() => setLoading({ ...loading, value: false }));
        } catch (e) {
            setLoading({ ...loading, value: false });
            enqueueSnackbar('Unable to fetch release data.', { variant: 'error' });
        }
    }
    const search = (search: string) => {
        const data = sortTableData(columns, search, collection.items);
        setData(data);
    };
    const logout = async () => {
        await AuthService.logout();
        Cookies.remove(`${process.env.REACT_APP_LOGIN_COOKIE}`);
        history.push('/Login');
    }
    const navigateToLogin = () => history.push('/Login');
    const onSearchBoxChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchValue(e.target.value);
    const onSearchBoxClear = () => setSearchValue('');
    const addItem = async () => {
        try {
            await addToUserCollection(username, item!.releaseId).then(addedItem => {
                setCollection({
                    ...collection,
                    numberOfItems: collection.numberOfItems + 1,
                    items: findIndex(collection.items, { releaseId: item!.releaseId }) !== -1 ? collection.items : [...collection.items, {
                        title: item!.title,
                        artist: item!.artist,
                        year: item!.year,
                        labels: item!.labels,
                        genres: item?.genres || '',
                        catno: item!.catnos,
                        releaseId: item!.releaseId,
                        image: item?.image || '',
                        instanceId: addedItem.instance_id
                    }]
                });
                setDialogs({
                    ...dialogs,
                    item: false,
                    addbyType: false
                });
                setIsNew(false);
                enqueueSnackbar(`${item!.title} - ${item!.artist} has successfully been added.`, { variant: 'success' });
            })
        } catch (e) { enqueueSnackbar(`Unable to add ${item!.title} - ${item!.artist}.`, { variant: 'error' }); }
    }
    const removeItem = async () => {
        if (item) {
            try {
                const itemIndex = findIndex(collection.items, { releaseId: item.releaseId });
                await removeFromUserCollection(username, item.releaseId, collection.items[itemIndex]!.instanceId).then(d => {
                    collection.items.splice(itemIndex, 1);
                    setCollection({
                        ...collection,
                        numberOfItems: collection.numberOfItems - 1,
                        items: collection.items
                    });
                    setDialogs({
                        ...dialogs,
                        item: false,
                        remove: false
                    });
                    enqueueSnackbar(`${item.title} - ${item.artist} has successfully been removed.`, { variant: 'success' });
                });
            } catch (e) { enqueueSnackbar(`Unable to remove ${item.title} - ${item.artist}.`, { variant: 'error' }); }
        }
    }
    const onToolbarMenuClose = () => setAnchorEl(null);
    const isDialogOpen = (): boolean => Object.values(dialogs).reduce((acc, curr) => acc || curr, false);
    const handleBarcodeReaderOnScan = async (barcode: string) => {
        if (!isDialogOpen() && barcode.length > 2) {
            setLoading({ value: true, label: 'Fetching release data...' })
            const items = await searchForReleaseBy('barcode', barcode);
            const scannedItem = items[0];
            const itemExists = findIndex(collection.items, { releaseId: scannedItem.releaseId }) !== -1;

            try {
                await getReleaseInformation(scannedItem.releaseId).then(info => {
                    setItem(info);
                    setIsNew(true);
                    setExists(itemExists);
                    setDialogs({
                        ...dialogs,
                        item: true
                    });
                }).finally(() => setLoading({ ...loading, value: false }));
            } catch (e) {
                setLoading({ ...loading, value: false });
                enqueueSnackbar('Unable to fetch release data.', { variant: 'error' });
            }
        }
    }
    const addByType = () => {
        toggleDialog('addbyType');
        setExists(false);
    }
    const donate = () => {
        window.open('http://paypal.me/jayjonesdev', '_blank');
        onToolbarMenuClose();
    }
    const contact = () => {
        window.open('mailto:jayjonesdevelopment@gmail.com', '_self');
        onToolbarMenuClose();
    }
    const onShareableClick = (event: React.MouseEvent<HTMLElement>) => {
        setPopoverAnchor(event.currentTarget);
        setPopoverOpen(true);
    }
    const copyShareableLink = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_URL}/View/${username}`);
        enqueueSnackbar('Link successfully copied.', { variant: 'success' });
        onShareableClose();
    }
    const onShareableClose = () => {
        setPopoverOpen(false);
        setPopoverAnchor(undefined);
    }

    return (
        <div className={classes.root}>
            <BarcodeReader onScan={handleBarcodeReaderOnScan} />
            <Toolbar anchorEl={anchorEl} onClose={onToolbarMenuClose} onShareableClick={onShareableClick} onMenuButtonClick={handleMenuButton} onLogoClick={navigateToLogin}>
                <NavDropdownMenuItem onClick={donate}>Donate</NavDropdownMenuItem>
                <NavDropdownMenuItem onClick={() => toggleDialog('info')}>Information</NavDropdownMenuItem>
                <NavDropdownMenuItem onClick={contact}>Contact</NavDropdownMenuItem>
                <NavDropdownMenuItem onClick={() => toggleDialog('logout')}>Logout</NavDropdownMenuItem>
            </Toolbar>
            <ShareableLinkPopover username={username} anchorEl={popoverAnchor} open={popoverOpen} onClose={onShareableClose} onClick={copyShareableLink} />
            <div className={classes.content}>
                <div className={classes.buttonBar}>
                    <Searchbox className={classes.searchBox} placeholder={searchDisabled ? 'Loading collection...' : `Search collection...`} color='primary' value={searchValue}
                        onChange={onSearchBoxChange} onClear={onSearchBoxClear} variant='outlined' disabled={searchDisabled} />
                    <ButtonBar>
                        <Button startIcon={viewType === 'image' ? <ViewList /> : <ViewDay />} size='large' variant={'contained'} color={'primary'} onClick={() => setViewType(viewType === 'image' ? 'text' : 'image')}>{viewType === 'image' ? 'View List' : 'View Tile'}</Button>
                        <Button startIcon={<Add />} size='large' variant={'contained'} color={'primary'} onClick={addByType}>Add By Type</Button>
                        {/* <Button startIcon={<LibraryAdd />} variant={'contained'} color={'primary'} onClick={() => { }}>Bulk Add</Button> */}
                    </ButtonBar>
                </div>
                <Divider color='primary' size={2} />
                {<div className={classes.tableContainer}>
                    {searchValue.length > 0 && data.length === 0 && <Typography variant={'subtitle1'}>No search results.</Typography>}
                    {!loading.value && data.length === 0 && searchValue.length === 0 && <Typography variant={'subtitle1'}>Please add items to your collection.</Typography>}
                    {(data.length > 0 && viewType === 'text') && <Grow in={true}>
                        <Table onRowClick={onRowClick} columns={columns} onColumnClick={onColumnClick} key='releaseId' data={data} />
                    </Grow>}
                    {(data.length > 0 && viewType === 'image') && <Grow in={true}>
                        <TileGrid onClick={onRowClick} imageKey={'image'} data={data} />
                    </Grow>}
                </div>}
            </div>
            <InformationDialog itemCount={collection.numberOfItems} collectionValue={collection.value} open={dialogs.info} onBackdropClick={() => toggleDialog('info')} />
            <Dialog open={dialogs.logout} title='Logout' content='Are you sure you want to logout?' onBackdropClick={() => toggleDialog('logout')}>
                <>
                    <Button color="primary" variant='outlined' onClick={() => toggleDialog('logout')}>Cancel</Button>
                    <Button color="primary" variant='contained' onClick={logout}>Logout</Button>
                </>
            </Dialog>
            <Dialog open={dialogs.remove} title='Remove' content={`Are you sure you want to remove ${item?.artist || ''} - ${item?.title || ''} from your collection?`} onBackdropClick={() => toggleDialog('remove')}>
                <>
                    <Button color="primary" variant='outlined' onClick={() => toggleDialog('remove')}>Cancel</Button>
                    <Button color="primary" variant='contained' onClick={removeItem}>Remove</Button>
                </>
            </Dialog>
            <ItemDialog isNew={isNew} exists={exists} open={dialogs.item} item={item} removeItem={() => toggleDialog('remove')} addItem={addItem} close={() => toggleDialog('item')} onBackdropClick={() => toggleDialog('item')} />
            <AddByTypeDialog open={dialogs.addbyType} viewItemDialog={(isNew: boolean) => {
                toggleDialog('item');
                setIsNew(isNew)
            }} setItem={setItem} close={() => toggleDialog('addbyType')} />
            <LoadingOverlay open={loading.value} type='circular' color="primary" label={loading.label} />
        </div>
    )
};

interface IProps {
    history: History<any>;
}

/*
    TODO:
    show all items in table and grid?
    fly-in filter for want or in collection items


    Or go with the mobile approach with a tab bar

    ---
    Condition of Vinyl
    ---
    Friends list? will require DB
    ---
    Bulk add, will need api rate limiting to implement
*/