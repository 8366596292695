import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import reject from 'lodash.reject';
import { rfc3986, sign } from "oauth-sign";
import { ICollectionItem } from './types';
import { OAuthInterceptorConfig, generateNonce, isAbsoluteURL, combineURLs, calculateBodyHash } from './oauth.service';
import { ITableColumn } from '../page/Collection/tableData';

const convertTinyIntToBoolean = (data: any) => {
    for (const property in data) {
        if (typeof data[property] === 'number' && !property.includes('id')) {
            if (data[property] === 0) data[property] = false;
            else if (data[property] === 1) data[property] = true;
        }
    }
    return data;
}

const dataURItoBlob = (dataURI: any) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

const getPreSignedURL = async (fileName: string, fileExtension: string, fileType: string) => await axios.get(`${process.env.REACT_APP_PRESIGNED_URL}?fileName=${fileName}&fileExtension=${fileExtension}&fileType=${fileType}`).then(res => res.data);

const uploadFileToPreSignedURL = async (url: string, body: any, contentType: string) => await axios.put(url, body, {
    headers: {
        'Content-Type': decodeURIComponent(contentType),
    }
}).then(res => res.data);

const buildNonce = () => {
    const length = 11;
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

export const removeDupsFromArray = (arr: string[]): string[] => [...new Set(arr)];

export const uniqByWithComparator = async (array: any[], iteratee: string, comparator: any): Promise<any[]> => {
    return await array.reduce((accumulator, currVal) => {
        if (currVal[iteratee] === comparator) accumulator.push(currVal);
        else if (!accumulator.find((obj: any) => obj[iteratee] === currVal[iteratee])) {
            accumulator.push(currVal);
        }
        return accumulator;
    }, []);
}

export const sortTableData = (columns: ITableColumn[], searchString: string, items: ICollectionItem[]): ICollectionItem[] => {
    const column = reject(columns, ['sort', null])[0];

    let sortedData = searchString.length > 0 ? items.filter(item => item.artist.toLowerCase().includes(searchString.toLowerCase())
        || item.title.toLowerCase().includes(searchString.toLowerCase())
        || item.labels.toLowerCase().includes(searchString.toLowerCase())
        || item.catno.toLowerCase().includes(searchString.toLowerCase())
        || item.genres.toLowerCase().includes(searchString.toLowerCase())) : items;

    if (column) {
        sortedData.sort((a: any, b: any) => {
            if (column.dataKey === 'year') {
                switch (column.sort) {
                    case 'ASC':
                        if ((a as any)[column.dataKey] > (b as any)[column.dataKey]) return 1;
                        if ((b as any)[column.dataKey] > (a as any)[column.dataKey]) return -1;
                        return 0;
                    case 'DESC':
                        if ((b as any)[column.dataKey] > (a as any)[column.dataKey]) return 1;
                        if ((a as any)[column.dataKey] > (b as any)[column.dataKey]) return -1;
                        return 0;
                    default:
                        if ((a as any)[column.dataKey] > (b as any)[column.dataKey]) return 1;
                        if ((b as any)[column.dataKey] > (a as any)[column.dataKey]) return -1;
                        return 0;
                }
            } else {
                switch (column.sort) {
                    case 'ASC':
                        if ((a as any)[column.dataKey].toLowerCase() > (b as any)[column.dataKey].toLowerCase()) return 1;
                        if ((b as any)[column.dataKey].toLowerCase() > (a as any)[column.dataKey].toLowerCase()) return -1;
                        return 0;
                    case 'DESC':
                        if ((b as any)[column.dataKey].toLowerCase() > (a as any)[column.dataKey].toLowerCase()) return 1;
                        if ((a as any)[column.dataKey].toLowerCase() > (b as any)[column.dataKey].toLowerCase()) return -1;
                        return 0;
                    default:
                        if ((a as any)[column.dataKey].toLowerCase() > (b as any)[column.dataKey].toLowerCase()) return 1;
                        if ((b as any)[column.dataKey].toLowerCase() > (a as any)[column.dataKey].toLowerCase()) return -1;
                        return 0;
                }
            }
        });
    }
    return sortedData;
}

export const authorizeRequest = (request: AxiosRequestConfig) => {
    const cookie = Cookies.get(`${process.env.REACT_APP_LOGIN_COOKIE}`) || '';
    const oauthData = cookie.length > 0 ? JSON.parse(cookie) : {};    
    const oauthConfig: OAuthInterceptorConfig = {
        key: process.env.REACT_APP_DISCOGS_CONSUMER_KEY || '',
        secret: process.env.REACT_APP_DISCOGS_CONSUMER_SECRET || '',
        algorithm: "HMAC-SHA1",
        token: oauthData.token,
        tokenSecret: oauthData.tokenSecret,
        includeBodyHash: "auto",
      }
    const method = (request.method || "GET").toUpperCase();
    const oauthParams: { [k: string]: string } = {
      oauth_consumer_key: process.env.REACT_APP_DISCOGS_CONSUMER_KEY || '',
      oauth_nonce: generateNonce(),
      oauth_signature_method: oauthConfig.algorithm,
      oauth_token: oauthConfig.token,
      oauth_timestamp: String(Math.floor(Date.now() * 0.001)),
      oauth_version: "1.0",
    };

    const oauthUrl = new URL(
      !request.baseURL || isAbsoluteURL(request.url || '')
        ? request.url || ''
        : combineURLs(request.baseURL || '', request.url || '')
    );

    const paramsToSign = { ...oauthParams };
    if (request.params) {
      for (const [k, v] of Object.entries(request.params)) {
        paramsToSign[k] = String(v);
      }
    }

    // Query parameters are hashed as part of params rather than as part of the URL
    if (oauthUrl.search) {
      oauthUrl.searchParams.forEach((value, key) => {
        paramsToSign[key] = value;
      });
      oauthUrl.search = "";
    }

    // Do not include hash in signature
    oauthUrl.hash = "";

    // Remove port if it is the default for that protocol
    if (
      (oauthUrl.protocol === "https:" && oauthUrl.port === "443") ||
      (oauthUrl.protocol === "http:" && oauthUrl.port === "80")
    ) {
      oauthUrl.port = "";
    }

    // If they are submitting a form, then include form parameters in the
    // signature as parameters rather than the body hash
    if (
      request.headers["content-type"] === "application/x-www-form-urlencoded"
    ) {
      new URLSearchParams(request.data).forEach((value, key) => {
        paramsToSign[key] = value;
      });
    } else if (
      oauthConfig.includeBodyHash === true ||
      (request.data &&
        oauthConfig.includeBodyHash === "auto" &&
        ["POST", "PUT"].includes(method))
    ) {
      oauthParams.oauth_body_hash = calculateBodyHash(oauthConfig.algorithm, request.data);
    }

    oauthParams.oauth_signature = sign(
      oauthConfig.algorithm,
      method,
      oauthUrl.toString(),
      paramsToSign,
      process.env.REACT_APP_DISCOGS_CONSUMER_SECRET || '',
      oauthConfig.tokenSecret
    );

    const authorization = [
      "OAuth",
      Object.entries(oauthParams)
        .map((e) => [e[0], '="', rfc3986(e[1]), '"'].join(""))
        .join(","),
    ].join(" ");

    return {
      ...request,
      headers: {
        ...request.headers,
        authorization,
      },
    };
  }

export default {
    convertTinyIntToBoolean,
    getPreSignedURL,
    uploadFileToPreSignedURL,
    dataURItoBlob,
    buildNonce
}

interface IDiscogOAuthCredentials {
    method: string;
    level: number;
    consumerKey: string;
    consumerSecret: string;
    token: string;
    tokenSecret: string;
}