import React from 'react';
import { Appbar, ButtonBar, NavDropdownMenu } from '@jayjonesdev/react-material-ui-library';
import { IconButton } from '@material-ui/core';
import { AccountCircle as AccountIcon, Home, Link } from '@material-ui/icons';
import useStyles from './style';
import icon from '../../assets/provinyl_logomark_6E7DAB_icon.png';

export default (props: IProps) => {
    const classes = useStyles();
    const { anchorEl, children, isPublic = false, onClose, onMenuButtonClick, onLogoClick, onShareableClick } = props;
    return (
        <>
            <Appbar className={classes.appbar}>
                <img className={classes.logo} src={icon} alt="logo" onClick={onLogoClick} />
                <ButtonBar>
                    {!isPublic ? <>
                        <IconButton
                            aria-label="link action"
                            aria-haspopup="false"
                            onClick={onShareableClick}
                            color="inherit"
                            title='Shareable Link'
                        >
                            <Link fontSize={'large'} />
                        </IconButton>
                        <IconButton
                            aria-label="menu action"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={onMenuButtonClick}
                            color="inherit"
                            title='Account'
                        >
                            <AccountIcon fontSize={'large'} />
                        </IconButton>
                        <NavDropdownMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
                            {children}
                        </NavDropdownMenu>
                    </> : <IconButton
                        aria-label="home action"
                        aria-haspopup="false"
                        onClick={onLogoClick}
                        color="inherit"
                        title='Home'
                    >
                        <Home fontSize={'large'} />
                    </IconButton>}
                </ButtonBar>
            </Appbar>
            <div className={classes.toolbar} />
        </>
    )
}

interface IProps {
    anchorEl: Element | null;
    children?: React.ReactNode;
    isPublic?: boolean;
    onClose: () => void;
    onLogoClick: () => void;
    onShareableClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onMenuButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
}